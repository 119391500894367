import React, { useEffect } from "react";

import MyHelmet from "@root/components/MyHelmet/MyHelmet";
import PreLoader from "@root/components/PreLoader/PreLoader";
import Template from "@root/components/Template/Template";
import Title from "@root/components/Title/Title";

import styles from "./styles.m.scss";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <PreLoader />
      <MyHelmet
        description='раздел "О нас" содержит общую информацию о rosamundi, о назначении проекта и контактную информацию
          редакции.'
        image="https://rosa-mundi.ru/og.jpg"
        title="ROSAMUNDI | О нас"
      />
      <Template backgroundImage="about" footer={true} header={true} headerColor={false}>
        <Title title="ROSAMUNDI" subtitle="[Π]ληροφορίες" black={false} />
        <div className={styles.paragraphContainer}>
          <div className={styles.paragraph}>
            Я убеждён, что истина — это александрийский стих или одиннадцатисложный. Что нужно взять палку; и пойти на
            вершину, как Боркман, как Рубек, как Брандт. Что аксиома: «Никто не может дать того, что не имеет»,
            неприменима в области нравственного. Я верю в слова премудрого Соломона — мудрость в порочную душу не
            входит, знание, если не иметь совести, способно лишь погубить. Что нужно заступаться за поэзию вопреки её
            заступникам и защищать её от тех, кто её оправдывает. Что кто попробовал серьезного, тому шутка, в
            особенности скучная, уже не понравится. Что нет проку фабриковать хорошее железо, когда собственная душа
            полна шлака. Что нет пользы приводить в порядок земельное угодье, когда человек не в ладу с собой. Я всегда
            считал, что поэт дома должен узнавать по номерам, горы — по высоте, детей — по дате их рождения, корабли по
            именам, женщин по фамилиям любовников, мужчин по их недостаткам, животных по достоинствам, планеты по
            определениям в гороскопе, а звезды — по их влиянию на жизнь того, чье рождение они осеняли.
          </div>
          <div className={styles.paragraph}>
            У rosamundi как проекта есть несколько целей. Первая — дать доступ поэту к читателю. Вторая — внедрить
            современных поэтов в общемировое литературное пространство. Третья — организовать анти-иерархическую
            структуру. Четвертая — выйти на рынок печатной литературы. Пятая — продолжить развитие концепции Даниила
            Андреева. По своей сути, rosamundi — это исследование человеческого опыта через поэзию и приглашение к связи
            с высшей духовной реальностью. Каждая встреча человека с человеком — это возможность для роста и
            трансформации. Я глубоко убеждён, что rosamundi способна стать катализатором метаморфоз внутри каждого из
            нас. Лично для меня rosamundi — это способ прикоснуться к этой высшей реальности, получить доступ к более
            глубоким брамфатурам, которые лежат под поверхностью нашей повседневной жизни.
          </div>
          <div className={styles.paragraph}>
            В конечном счете, rosamundi — это праздник человеческого духа и его бесконечной способности к творчеству,
            проницательности и состраданию. Поэзия способна пробудить в нас наш истинный потенциал, соединить нас с
            красотой и удивительностью окружающего мира, вдохновить нас быть проводниками позитивных изменений в наших
            жизнях и в мире в целом. Я приглашаю вас присоединиться к нам в этом путешествии.
          </div>
          <div className={styles.paragraph}>
            С неба упало три яблока: первое тому, кто рассказывал, второе тому, кто слушал, третье тому, кто понял.
          </div>
          <div className={styles.paragraph}>
            <div className={styles.bold}> Безвозмездно поддержать развитие проекта:</div>
            <div>
              <a href="https://pay.cloudtips.ru/p/236e83ec" target="_blank" rel="noreferrer">
                На платформе CloudTips
              </a>
            </div>
            <div>Прямым переводом на карты с комментарием “rosamundi”:</div>
            <div>5469 9804 7096 1224 (СБЕР)</div>
            <div>5536 9139 5457 9708 (Тинькофф)</div>
          </div>
          <div className={styles.paragraph}>
            <div className={styles.bold}>Предложить свою рукопись:</div>
            <div>
              <a href="mailto:rosa.mundi.redaction@gmail.com">rosa.mundi.redaction@gmail.com</a>
            </div>
            <div>
              <a href="https://vk.com/rosamundi" target="_blank" rel="noreferrer">
                https://vk.com/rosamundi
              </a>
            </div>
            <div>(и прочими доступными способами)</div>
          </div>
          <div className={styles.paragraph}>
            Редакция rosamundi оставляет за собой право вносить изменения в предоставленные материалы.
          </div>
        </div>
      </Template>
    </>
  );
};

export default About;
